
.output {
  background-color: #00ffff;
  border-color: #00ffff;
  width: 100px;
  display: inline;
}

.pagerW {
  max-width: 1400px;
  margin: auto;
}

.blue-text {
  color: #106ebe;
  font-family: Segoe UI Light;
}

.gray-black-text {
  color: rgb(50, 49, 48) /*rgb(138, 136, 134)*/;
  font-family: Segoe UI Light;
}

.gray-black-text h3 {
  color: rgb(50, 49, 48);
}

.gray-black-text summary {
  font-size: x-large;
}

.white-text {
  background-color: #106ebe;
  color: white;
  font-size: x-large;
  font-family: Segoe UI Light;
}

.blue-circle {
  background-image: url(kreis-blau.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  min-height: 40px;
}

.blue-circle-text {
  text-align: center;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.container {
  min-height: 200px;
  font-family: Segoe UI Light;
}

.drop-zone {
  /*
      Display as inline block to automatically expand the height for many files.
      But then we need to enforce a mimimum width and height for when there are no/few files.
  */
  min-height: 200px;
  min-width: 100%;
  display: inline-block;
  border-width: 2px;
  margin-bottom: 20px;
  border-style: dashed;
  line-height: 200px;
  text-align: center
}

.upload-drop-zone {
  color: #ccc;
  border-color: #ccc;
}

.highlight-drop-zone {
  color: #808080;
  border-color: #808080;
}

.preview-drop-zone {
  color: #000000;
  border-color: #ccc;
}

div.drop-zone div.row .col-md-2 {
  margin-top: 5px;
  margin-left: 12px;
  height: 100px;
  width: 150px;
  line-height: 14px;
  text-align: center;
}

div.drop-zone div.row img {
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mappingFigure {
  margin-top: 20px;
  margin-right: 20px;
  text-align: center;
  text-overflow: ellipsis;
  width: 100px;
  height: 80px;
  white-space: nowrap;
  position: relative;
  left: 200px;
}

.mappingFigure img {
  height: 50px;
}

.mapping-row {
  position: relative;
}

.canvas {
  position: absolute;
  margin: 0;
}

.canvas-button {
  position: relative;
  border: none;
  width: 20px;
  height: 20px;
  margin: 20px;
  border-radius: 50%;
  background-color: mediumaquamarine;
  z-index: 1;
}

.buttons-bar {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 80px;
  z-index: 2;
}

.buttons-bar button {
  margin-top: 25px
}

.help-text {
  margin: 40px 80px 80px 80px;
}

.load-screen {
  position: absolute;
  width: 40px;
  top: 14px;
  margin-left: 350px;
}
